$staticUrl: 'https://static.likehostels.ru';
@import "app/custom-variables";

likehostel-index {
  h3 {
    font-weight: 300;
    font-size: 2em;
  }

  .likehostel-screen {
    &:before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-image: url("#{$staticUrl}/img/homescreen-v1.jpg");
      background-size: cover;
      content: "";
      z-index: -1;
      filter: blur(5px);
    }

    position: relative;
    background-color: rgba(0, 0, 0, .25);
    color: white;
    padding-top: 4rem;

    a {
      color: white;
    }

    h1, h2 {
      text-align: center;
    }
    h1 {
      font-size: 2.5em;
      font-weight: bold;
    }
    h2 {
      font-size: 1.5em;
      font-weight: 300;
    }
    @media (max-width: 1260px) {
      h1 {
        font-size: 2em;
      }
    }
    @media (max-width: 800px) {
      h1 {
        font-size: 1.8em;
      }
    }
    @media (max-width: 560px) {
      h1 > span {
        display: block;
      }
      h1 > br {
        display: none;
      }
    }
    .dropdown-menu, .dropdown-item {
      color: black;
    }
  }

  .likehostel-description {
    p {
      font-size: 1.1em;
    }
  }

  .franshiza-circle {
    color: white;
    text-align: center;
    margin: 0 auto;
    background-size: cover;
    background: url("#{$staticUrl}/img/millionaire-v1.jpg") center;

    &, .franshiza-wrapper {
      width: 491px;
      height: 491px;
    }
    .franshiza-wrapper {
      display: table-cell;
      vertical-align: middle;
    }

    @media (max-width: 600px) {
      background-image: url("#{$staticUrl}/img/millionaire-mob-v1.jpg");
      background-size: cover;
      &, .franshiza-wrapper {
        width: 100%;
        height: inherit;
        padding-top: 20px;
        padding-bottom: 20px;
        display: block;
      }
    }

    h3 {
      margin-bottom: 20px;
    }
    h4 {
      font-size: 2.3em;
      font-weight: bold;
      margin-bottom: 30px;
    }
  }

  .photos-slick {
    &, img {
      height: 180px;
      min-width: 180px;
    }
  }
  .slick-prev {
    left: 20px;
  }
  .slick-next {
    right: 20px;
  }

  cities-list {
    a, a:hover {
      color: black;
    }
  }

  .likehostel-stat {
    .item, .value {
      display: block;
    }
    .item {
      color: $like;
      font-weight: bold;
      font-size: 3em;
    }
    .value {
      font-weight: bold;
      font-size: 1.3em;
      line-height: 1.2em;
    }
  }

  hostel-whats-in {
    display: block;
    margin-top: 80px;
    margin-bottom: 80px;
  }

  ul.likehostel-countries {
    list-style: none;
    padding-left: 0;
    display: block;
    font-size: 1.3em;
    text-align: center;

    > li {
      display: inline-block;
      background-position: left center;
      background-size: 30px 23px;
      padding-left: 40px;
      margin-left: 40px;
      &.flag-russia {
        margin-left: 0;
      }
    }

    @media (max-width: 1000px) {
      column-count: 3;
      > li {
        margin-left: 0;
        width: 170px;
        text-align: left;
        margin-bottom: 10px;
      }
    }
    @media (max-width: 600px) {
      column-count: 2;
    }
  }

  cities-list {
    a, a:hover {
      color: black;
    }
  }

}
